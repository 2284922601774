.toolbar {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  top: 0;
  left: 0;
  width: 100%;

  background: #006494;
  height: 56px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 1;
}

.spacer__befor__logo {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #fa923f;
}

@media (max-width: 768px) {
  .toolbar_navigation-items {
    display: none;
  }

  .spacer {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }

  .spacer__befor__logo {
    display: none;
  }
}
